import React from 'react'
import moment from 'moment'
import { render } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import { LANGUAGE } from 'constants/common'
import { getItem } from 'utils/store'
import i18n from 'translations/i18n'
import trTR from 'antd/es/locale/tr_TR'
import enUS from 'antd/es/locale/en_US'
import 'moment/locale/tr'
import App from './app'
import './index.css'

const lang = getItem('lang') || LANGUAGE.EN.code

i18n.changeLanguage(lang)
moment.locale(lang)

render(
  <BrowserRouter>
    <ConfigProvider locale={lang === 'EN' ? enUS : trTR}>
      <App />
    </ConfigProvider>
  </BrowserRouter>,
  document.getElementById('root')
)
