import { createUseStyles } from 'react-jss'
import theme from 'config/theme'

const useStyles = createUseStyles({
  logoWrapper: {
    fontSize: 22,
    textAlign: 'center',
    fontWeight: 'bold',
    cursor: 'pointer',
    margin: 10,
    padding: '0px 10px',
    borderRadius: '5px 15px',
    color: '#fff',
    backgroundColor: theme.color.primary,
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
    KhtmlUserSelect: 'none',
    userSelect: 'none',
    msUserSelect: 'none',
  },
})

export default useStyles
