import React, { useState } from 'react'
import moment from 'moment'
import { get } from 'lodash'
import { useTranslation } from 'react-i18next'
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Col,
  message,
  Switch,
} from 'antd'
import { CustomButton, StatusSelect } from 'components'
import { updateDividend } from 'api/dividend'
import { RESPONSE_STATUS } from 'constants/common'
import useStyles from './styles'

const UpdateDividendForm = ({ item, onCancel, refetchDividends }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [updateLoading, setUpdateLoading] = useState(false)
  const [dividendDeleted, setDividendDeleted] = useState(get(item, 'isDeleted'))

  const handleSubmit = async ({ amount, exDate, paymentDate, status }) => {
    try {
      setUpdateLoading(true)
      const response = await updateDividend({
        id: get(item, 'id'),
        amount,
        exDate: exDate && exDate.format('YYYY-MM-DD'),
        paymentDate: paymentDate && paymentDate.format('YYYY-MM-DD'),
        status: Number(status, 10),
        isDeleted: dividendDeleted,
      })
      setUpdateLoading(false)
      message.success(t('MESSAGES.UPDATE_SUCCESS'))
      if (response.data.status === RESPONSE_STATUS.SUCCESS) {
        refetchDividends()
      }
      onCancel()
    } catch (error) {
      message.error(error.message)
      setUpdateLoading(false)
    }
  }

  return (
    <Form
      layout="vertical"
      onFinish={handleSubmit}
      initialValues={{
        status: `${get(item, 'status')}`,
        amount: get(item, 'amount'),
        exDate: get(item, 'exDate') && moment(item.exDate),
        paymentDate: get(item, 'paymentDate') && moment(item.paymentDate),
      }}
      requiredMark={false}
    >
      <Form.Item label={t('PAGES.DIVIDENDS.TABLE.FIELDS.STOCK')}>
        <Input disabled value={get(item, 'symbol')} />
      </Form.Item>
      <Form.Item
        name="amount"
        label={t('PAGES.DIVIDENDS.TABLE.FIELDS.AMOUNT')}
        rules={[{ required: true }]}
      >
        <InputNumber
          className={classes.fullWidth}
          placeholder={t('PAGES.DIVIDENDS.TABLE.FIELDS.AMOUNT')}
        />
      </Form.Item>
      <Row gutter={8}>
        <Col span={12}>
          <Form.Item
            name="exDate"
            label={t('PAGES.DIVIDENDS.TABLE.FIELDS.EX_DATE')}
          >
            <DatePicker
              className={classes.fullWidth}
              placeholder={t('PAGES.DIVIDENDS.TABLE.FIELDS.EX_DATE')}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="paymentDate"
            label={t('PAGES.DIVIDENDS.TABLE.FIELDS.PAYMENT_DATE')}
          >
            <DatePicker
              className={classes.fullWidth}
              placeholder={t('PAGES.DIVIDENDS.TABLE.FIELDS.PAYMENT_DATE')}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col span={12}>
          <Form.Item
            name="status"
            label={t('PAGES.DIVIDENDS.TABLE.FIELDS.DIV_STATUS')}
          >
            <StatusSelect />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={t('COMMON.STATUS')}>
            <Switch
              checkedChildren={t('COMMON.ACTIVE')}
              unCheckedChildren={t('COMMON.NOT_ACTIVE')}
              defaultChecked={!get(item, 'isDeleted')}
              onChange={(checked) => setDividendDeleted(checked ? 0 : 1)}
            />
          </Form.Item>
        </Col>
      </Row>
      <div className={classes.btnContainer}>
        <Button className={classes.cancelBtn} onClick={onCancel}>
          {t('COMMON.CANCEL')}
        </Button>
        <CustomButton type="primary" htmlType="submit" loading={updateLoading}>
          {t('COMMON.UPDATE')}
        </CustomButton>
      </div>
    </Form>
  )
}

export default UpdateDividendForm
