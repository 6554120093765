import React from 'react'
import { useTranslation } from 'react-i18next'
import { ContentLayout } from 'layouts'
import { SEO } from 'components'
import StocksTable from './Table'

const Stocks = () => {
  const { t } = useTranslation()

  return (
    <>
      <SEO title={t('PAGES.STOCKS.TITLE')} />
      <ContentLayout pageTitle={t('PAGES.STOCKS.TITLE')}>
        <StocksTable />
      </ContentLayout>
    </>
  )
}

export default Stocks
