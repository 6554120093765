import React, { useState } from 'react'
import { trim } from 'lodash'
import { useTranslation } from 'react-i18next'
import { Button, Form, Input, message, Row, Col, Select } from 'antd'
import { CustomButton } from 'components'
import { COMPONENT_TYPE, REGION, RESPONSE_STATUS } from 'constants/common'
import { insertCollection } from 'api/collection'
import useStyles from './styles'

const { TextArea } = Input
const { Option } = Select

const AddCollectionForm = ({ onCancel }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [addLoading, setAddLoading] = useState(false)

  const handleSubmit = async ({
    title,
    description,
    region,
    icon,
    queryString,
    componentType,
  }) => {
    try {
      const response = await insertCollection({
        title: trim(title),
        description: trim(description),
        region,
        icon: trim(icon),
        queryString: trim(queryString),
        componentType,
      })
      setAddLoading(false)
      message.success(t('MESSAGES.UPDATE_SUCCESS'))
      if (response.data.status === RESPONSE_STATUS.SUCCESS) {
        setTimeout(() => window.location.reload(), 100)
      }
      onCancel()
    } catch (error) {
      message.error(error.message)
      setAddLoading(false)
    }
  }

  return (
    <Form layout="vertical" onFinish={handleSubmit} requiredMark={false}>
      <Row gutter={8}>
        <Col span={12}>
          <Form.Item
            name="region"
            label={t('PAGES.COLLECTIONS.TABLE.FIELDS.REGION')}
            rules={[{ required: true }]}
          >
            <Select placeholder={t('PAGES.COLLECTIONS.SELECT_REGION')}>
              {Object.values(REGION).map(({ code }) => (
                <Option key={code} value={code}>
                  {code}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="icon"
            label={t('PAGES.COLLECTIONS.TABLE.FIELDS.ICON')}
            rules={[{ required: true }]}
          >
            <Input placeholder={t('PAGES.COLLECTIONS.TABLE.FIELDS.ICON')} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="title"
        label={t('PAGES.COLLECTIONS.TABLE.FIELDS.TITLE')}
        rules={[{ required: true }]}
      >
        <Input placeholder={t('PAGES.COLLECTIONS.TABLE.FIELDS.TITLE')} />
      </Form.Item>
      <Form.Item
        name="description"
        label={t('PAGES.COLLECTIONS.TABLE.FIELDS.DESCRIPTION')}
        rules={[{ required: true }]}
      >
        <TextArea
          rows={2}
          placeholder={t('PAGES.COLLECTIONS.TABLE.FIELDS.DESCRIPTION')}
          showCount
          maxLength={140}
        />
      </Form.Item>
      <Form.Item
        name="queryString"
        label={t('PAGES.COLLECTIONS.TABLE.FIELDS.QUERY')}
        rules={[{ required: true }]}
      >
        <TextArea
          rows={2}
          placeholder={t('PAGES.COLLECTIONS.TABLE.FIELDS.QUERY')}
        />
      </Form.Item>
      <Row gutter={8}>
        <Col span={12}>
          <Form.Item
            name="componentType"
            label={t('PAGES.COLLECTIONS.TABLE.FIELDS.COMPONENT_TYPE')}
            rules={[{ required: true }]}
          >
            <Select placeholder={t('PAGES.COLLECTIONS.SELECT_COMPONENT_TYPE')}>
              {Object.values(COMPONENT_TYPE).map(({ key }) => (
                <Option key={key} value={key}>
                  {key}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <div className={classes.btnContainer}>
        <Button className={classes.cancelBtn} onClick={onCancel}>
          {t('COMMON.CANCEL')}
        </Button>
        <CustomButton type="primary" htmlType="submit" loading={addLoading}>
          {t('COMMON.CREATE')}
        </CustomButton>
      </div>
    </Form>
  )
}

export default AddCollectionForm
