import React from 'react'
import { Space, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import useStyles from './styles'

const Pagination = ({
  onPrevClick,
  onNextClick,
  prevDisabled,
  nextDisabled,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.root}>
      <Space>
        <Button onClick={onPrevClick} disabled={prevDisabled}>
          {t('COMMON.BACK')}
        </Button>
        <Button onClick={onNextClick} disabled={nextDisabled}>
          {t('COMMON.NEXT')}
        </Button>
      </Space>
    </div>
  )
}

export default Pagination
