import React, { Fragment, useState, useContext } from 'react'
import { useGoogleLogout } from 'react-google-login'
import { get } from 'lodash'
import { Avatar, Layout, Menu, Dropdown, message, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { AuthContext } from 'context/auth'
import { SidebarMenu } from 'components'
import { LANGUAGE } from 'constants/common'
import { getItem, storeItem, removeItem } from 'utils/store'
import useStyles from './styles'

const { Header, Footer } = Layout

const MainLayout = ({ children }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    authDispatch,
    authState: { currentUser },
  } = useContext(AuthContext)

  const [language, setLanguage] = useState(getItem('lang') || LANGUAGE.EN.code)

  const handleLangSelect = (langCode) => {
    storeItem('lang', langCode)
    setLanguage(langCode)
    window.location.reload()
  }

  const onLogoutSuccess = () => {
    authDispatch({ type: 'LOGOUT_USER' })
    removeItem('currentUser')
    window.location = '/'
  }

  const onFailure = ({ error }) => {
    message.error(error)
  }

  const { signOut } = useGoogleLogout({
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    onLogoutSuccess,
    onFailure,
  })

  const langMenu = (
    <Menu selectedKeys={[language]}>
      {Object.values(LANGUAGE).map((lang, index) => {
        return (
          <Fragment key={lang.code}>
            <Menu.Item
              key={lang.code}
              onClick={() => handleLangSelect(lang.code)}
            >
              {lang.title}
            </Menu.Item>
            {index !== Object.values(LANGUAGE).length - 1 && <Menu.Divider />}
          </Fragment>
        )
      })}
    </Menu>
  )

  const profileMenu = (
    <Menu>
      <Menu.Item key="0">
        <span style={{ fontWeight: 600, color: '#1890ff' }}>
          {get(currentUser, 'name')}
        </span>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="1" onClick={signOut}>
        {t('COMMON.LOGOUT')}
      </Menu.Item>
    </Menu>
  )

  return (
    <Layout className={classes.mainLayout}>
      <SidebarMenu lang={language} />
      <Layout>
        <Header className={classes.headerWrapper}>
          <Space size={15}>
            <Dropdown overlay={langMenu} trigger={['click']}>
              <a href='/#"' onClick={(e) => e.preventDefault()}>
                <span className={classes.langText}>{language}</span>
              </a>
            </Dropdown>
            <Dropdown overlay={profileMenu} trigger={['click']}>
              <a href='/#"' onClick={(e) => e.preventDefault()}>
                <Avatar size={38} src={get(currentUser, 'imageUrl')} />
              </a>
            </Dropdown>
          </Space>
        </Header>
        <div className={classes.contentWrapper}>{children}</div>
        <Footer className={classes.footerWrapper}>
          {t('PROJECT_NAME')} Panel © {`${new Date().getFullYear()}`}
        </Footer>
      </Layout>
    </Layout>
  )
}

export default MainLayout
