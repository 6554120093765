import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  root: {
    padding: '15px 0',
    display: 'flex',
    justifyContent: 'center',
  },
})

export default useStyles
