import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  root: {
    padding: 20,
    maxWidth: 500,
  },
})

export default useStyles
