import { createUseStyles } from 'react-jss'
import theme from 'config/theme'

const useStyles = createUseStyles({
  mainLayout: {
    height: '100vh',
  },
  headerWrapper: {
    background: '#fff',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '0 20px',
  },
  toggleMenuIcon: {
    fontSize: '18px',
    lineHeight: '64px',
    padding: '0 24px',
    cursor: 'pointer',
    transition: 'color 0.3s',

    '&:hover': {
      color: theme.color.primary,
    },
  },
  contentWrapper: {
    margin: '10px 15px',
  },
  footerWrapper: {
    textAlign: 'center',
  },
  langText: {
    fontSize: 18,
    marginTop: 20,
    fontWeight: 700,
  },
})

export default useStyles
