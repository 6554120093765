import axios from 'utils/axios'

export const getStockList = async ({
  start,
  limit,
  orderBy,
  region,
  isDeleted,
  symbol,
}) => {
  return axios.post('/stock/select', {
    start,
    limit,
    orderBy,
    region,
    isDeleted,
    symbol,
  })
}

export const deleteStock = async ({ id }) => {
  return axios.post('/stock/delete', {
    stockId: id,
  })
}

export const updateStock = async ({ id, symbol, name, region, isDeleted }) => {
  return axios.post('/stock/update', {
    stockId: id,
    symbol,
    name,
    region,
    isDeleted,
  })
}
