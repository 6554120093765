import axios from 'utils/axios'

export const getCollectionList = async ({
  start,
  limit,
  orderBy,
  region,
  isDeleted,
}) => {
  return axios.post('/collection/select', {
    start,
    limit,
    orderBy,
    region,
    isDeleted,
  })
}

export const deleteCollection = async ({ id }) => {
  return axios.post('/collection/delete', {
    collectionId: id,
  })
}

export const insertCollection = async ({
  region,
  title,
  description,
  icon,
  componentType,
  queryString,
}) => {
  return axios.post('/collection/insert', {
    region,
    title,
    description,
    icon,
    componentType,
    queryString,
  })
}

export const updateCollection = async ({
  id,
  region,
  title,
  description,
  icon,
  componentType,
  queryString,
  isDeleted,
}) => {
  return axios.post('/collection/update', {
    collectionId: id,
    region,
    title,
    description,
    icon,
    componentType,
    queryString,
    isDeleted,
  })
}
