export const DIVIDEND_STATUS = {
  200: {
    status: 200,
    title: {
      TR: 'Karar Verildi',
      EN: 'Approved',
    },
    color: 'green',
  },
  300: {
    status: 300,
    title: {
      TR: 'Karar Aşamasında',
      EN: 'Pending',
    },
    color: 'orange',
  },
}

export const LANGUAGE = {
  EN: {
    title: 'English',
    code: 'EN',
  },
  TR: {
    title: 'Türkçe',
    code: 'TR',
  },
}

export const RESPONSE_STATUS = {
  SUCCESS: 200,
}

export const REGION = {
  TR: {
    code: 'TR',
  },
  US: {
    code: 'US',
  },
}

export const COMPONENT_TYPE = {
  STOCK: {
    key: 'stock',
  },
  DIVIDEND: {
    key: 'dividend',
  },
}

export const ADMIN_EMAILS = [
  'ebky14@gmail.com', // Ebru
  'tokakburak@gmail.com', // Burak
  'gamertavsan2005@gmail.com', // Emre
]
