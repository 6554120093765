import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, DatePicker, Form, InputNumber, Row, Col, message } from 'antd'
import { CustomButton, StockSelect, StatusSelect } from 'components'
import { DIVIDEND_STATUS, RESPONSE_STATUS } from 'constants/common'
import { insertDividend } from 'api/dividend'
import useStyles from './styles'

const AddDividendForm = ({ onCancel }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [addLoading, setAddLoading] = useState(false)

  const handleSubmit = async ({
    stockId,
    amount,
    exDate,
    paymentDate,
    status,
  }) => {
    try {
      setAddLoading(true)
      const response = await insertDividend({
        stockId,
        amount,
        exDate: exDate && exDate.format('YYYY-MM-DD'),
        paymentDate: paymentDate && paymentDate.format('YYYY-MM-DD'),
        status: Number(status, 10),
      })
      setAddLoading(false)
      message.success(t('MESSAGES.UPDATE_SUCCESS'))
      if (response.data.status === RESPONSE_STATUS.SUCCESS) {
        setTimeout(() => window.location.reload(), 100)
      }
      onCancel()
    } catch (error) {
      message.error(error.message)
      setAddLoading(false)
    }
  }

  return (
    <Form
      layout="vertical"
      onFinish={handleSubmit}
      initialValues={{ status: `${DIVIDEND_STATUS[200].status}` }}
      requiredMark={false}
    >
      <Form.Item
        name="stockId"
        label={t('PAGES.DIVIDENDS.TABLE.FIELDS.STOCK')}
        rules={[{ required: true }]}
      >
        <StockSelect />
      </Form.Item>
      <Form.Item
        name="amount"
        label={t('PAGES.DIVIDENDS.TABLE.FIELDS.AMOUNT')}
        rules={[{ required: true }]}
      >
        <InputNumber
          className={classes.fullWidth}
          placeholder={t('PAGES.DIVIDENDS.TABLE.FIELDS.AMOUNT')}
        />
      </Form.Item>
      <Row gutter={8}>
        <Col span={12}>
          <Form.Item
            name="exDate"
            label={t('PAGES.DIVIDENDS.TABLE.FIELDS.EX_DATE')}
          >
            <DatePicker
              className={classes.fullWidth}
              placeholder={t('PAGES.DIVIDENDS.TABLE.FIELDS.EX_DATE')}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="paymentDate"
            label={t('PAGES.DIVIDENDS.TABLE.FIELDS.PAYMENT_DATE')}
          >
            <DatePicker
              className={classes.fullWidth}
              placeholder={t('PAGES.DIVIDENDS.TABLE.FIELDS.PAYMENT_DATE')}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col span={12}>
          <Form.Item
            name="status"
            label={t('PAGES.DIVIDENDS.TABLE.FIELDS.DIV_STATUS')}
          >
            <StatusSelect firstSelected />
          </Form.Item>
        </Col>
      </Row>
      <div className={classes.btnContainer}>
        <Button className={classes.cancelBtn} onClick={onCancel}>
          {t('COMMON.CANCEL')}
        </Button>
        <CustomButton type="primary" htmlType="submit" loading={addLoading}>
          {t('COMMON.CREATE')}
        </CustomButton>
      </div>
    </Form>
  )
}

export default AddDividendForm
