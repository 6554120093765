export const sidebarMenuItems = {
  EN: [
    {
      key: 'dashboard',
      icon: 'dashboard', // antdesign icon key
      title: 'Dashboard',
      path: '/dashboard',
    },
    {
      key: 'users',
      icon: 'users',
      title: 'Users',
      path: '/users',
    },
    {
      key: 'stocks',
      icon: 'stock',
      title: 'Stocks',
      path: '/stocks',
    },
    {
      key: 'dividends',
      icon: 'dividend',
      title: 'Dividends',
      path: '/dividends',
    },
    {
      key: 'collections',
      icon: 'collection',
      title: 'Collections',
      path: '/collections',
    },
    {
      key: 'tools',
      icon: 'tools',
      title: 'Tools',
      path: '/tools',
      subItems: [
        {
          key: 'parseUrl',
          title: 'Create Post',
          path: '/parseUrl',
        },
        {
          key: 'calculateYield',
          title: 'Calculate Yield',
          path: '/calculateYield',
        },
      ],
    },
  ],
  TR: [
    {
      key: 'dashboard',
      icon: 'dashboard', // antdesign icon key
      title: 'Anasayfa',
      path: '/dashboard',
    },
    {
      key: 'users',
      icon: 'users',
      title: 'Kullanıcılar',
      path: '/users',
    },
    {
      key: 'stocks',
      icon: 'stock',
      title: 'Hisseler',
      path: '/stocks',
    },
    {
      key: 'dividends',
      icon: 'dividend',
      title: 'Temettüler',
      path: '/dividends',
    },
    {
      key: 'collections',
      icon: 'collection',
      title: 'Koleksiyonlar',
      path: '/collections',
    },
    {
      key: 'tools',
      icon: 'tools',
      title: 'Araçlar',
      path: '/tools',
      subItems: [
        {
          key: 'parseUrl',
          title: 'Gönderi Oluştur',
          path: '/parseUrl',
        },
        {
          key: 'calculateYield',
          title: 'Verim Hesapla',
          path: '/calculateYield',
        },
      ],
    },
  ],
}
