import React from 'react'
import useStyles from './styles'

const Error = ({ message = 'Error :(' }) => {
  const classes = useStyles()

  return <div className={classes.error}>{message}</div>
}

export default Error
