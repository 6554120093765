import React, { useState } from 'react'
import { get, trim } from 'lodash'
import { useTranslation } from 'react-i18next'
import { Button, Form, Input, Select, Switch, Row, Col, message } from 'antd'
import { updateStock } from 'api/stock'
import { REGION, RESPONSE_STATUS } from 'constants/common'
import { CustomButton } from 'components'
import useStyles from './styles'

const { Option } = Select

const UpdateStockForm = ({ item, onCancel, refetchStocks }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [updateLoading, setUpdateLoading] = useState(false)
  const [stockDeleted, setStockDeleted] = useState(get(item, 'isDeleted'))

  const handleSubmit = async ({ symbol, name, region }) => {
    try {
      setUpdateLoading(true)
      const response = await updateStock({
        id: get(item, 'id'),
        symbol: trim(symbol),
        region,
        name: trim(name),
        isDeleted: stockDeleted,
      })
      setUpdateLoading(false)
      message.success(t('MESSAGES.UPDATE_SUCCESS'))
      if (response.data.status === RESPONSE_STATUS.SUCCESS) {
        refetchStocks()
      }
      onCancel()
    } catch (error) {
      message.error(error.message)
      setUpdateLoading(false)
    }
  }

  return (
    <Form
      layout="vertical"
      onFinish={handleSubmit}
      initialValues={{
        name: get(item, 'name'),
        symbol: get(item, 'symbol'),
        region: get(item, 'region'),
      }}
      requiredMark={false}
    >
      <Row gutter={8}>
        <Col span={12}>
          <Form.Item
            name="symbol"
            label={t('PAGES.STOCKS.TABLE.FIELDS.SYMBOL')}
            normalize={(value) => (value || '').toUpperCase()}
            rules={[{ required: true }]}
          >
            <Input placeholder={t('PAGES.STOCKS.TABLE.FIELDS.SYMBOL')} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="region"
            label={t('PAGES.STOCKS.TABLE.FIELDS.REGION')}
            rules={[{ required: true }]}
          >
            <Select placeholder={t('PAGES.STOCKS.SELECT_REGION')}>
              {Object.values(REGION).map(({ code }) => (
                <Option key={code} value={code}>
                  {code}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="name"
        label={t('PAGES.STOCKS.TABLE.FIELDS.NAME')}
        rules={[{ required: true }]}
      >
        <Input placeholder={t('PAGES.STOCKS.TABLE.FIELDS.NAME')} />
      </Form.Item>
      <Form.Item label={t('COMMON.STATUS')}>
        <Switch
          checkedChildren={t('COMMON.ACTIVE')}
          unCheckedChildren={t('COMMON.NOT_ACTIVE')}
          defaultChecked={!get(item, 'isDeleted')}
          onChange={(checked) => setStockDeleted(checked ? 0 : 1)}
        />
      </Form.Item>
      <div className={classes.btnContainer}>
        <Button className={classes.cancelBtn} onClick={onCancel}>
          {t('COMMON.CANCEL')}
        </Button>
        <CustomButton type="primary" htmlType="submit" loading={updateLoading}>
          {t('COMMON.UPDATE')}
        </CustomButton>
      </div>
    </Form>
  )
}

export default UpdateStockForm
