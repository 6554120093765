import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash'
import { DataTable, Error, Pagination, Spinner } from 'components'
import { getUserList as getUserListApi } from 'api/user'
import { getTableColumns } from './config'

const LIMIT = 10
const INITIAL_OFFSET = 0

const UsersTable = () => {
  const { t } = useTranslation()

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [limit] = useState(LIMIT)
  const [offset, setOffset] = useState(INITIAL_OFFSET)

  const fetchUsers = async () => {
    try {
      setLoading(true)
      const response = await getUserListApi({
        limit,
        start: offset,
        orderBy: 'id desc',
      })
      setData(get(response, 'data.result'))
      setLoading(false)
    } catch (err) {
      setError(err)
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchUsers()
  }, [offset])

  if (loading) {
    return <Spinner />
  }
  if (error) {
    return <Error message={error.message} />
  }

  return (
    <>
      <DataTable
        data={data}
        loading={loading}
        columns={getTableColumns({ t })}
        pagination={false}
      />
      <Pagination
        onPrevClick={() => setOffset(offset - limit)}
        onNextClick={() => setOffset(offset + limit)}
        prevDisabled={offset - limit < 0}
        nextDisabled={data.length < limit}
      />
    </>
  )
}

export default UsersTable
