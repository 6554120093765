import React from 'react'
import { useHistory, useLocation, withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Menu, Layout } from 'antd'
import { sidebarMenuItems } from 'config/sidebar'
import { renderIcon, getOpenSubItemKey } from './utils'
import useStyles from './styles'

const { Sider } = Layout
const { SubMenu } = Menu

const SidebarMenu = ({ lang }) => {
  const classes = useStyles()
  const history = useHistory()
  const { pathname } = useLocation()
  const { t } = useTranslation()

  const menuItems = sidebarMenuItems[lang]

  const handleLogoClick = () => {
    window.location = '/dashboard'
  }

  return (
    <Sider
      trigger={null}
      collapsible={window.screen.width < 600}
      collapsed={window.screen.width < 600}
    >
      <div
        className={classes.logoWrapper}
        onClick={handleLogoClick}
        onKeyDown={handleLogoClick}
        role="button"
        tabIndex={0}
      >
        {window.screen.width < 600 ? t('MAIN_SHORT_TITLE') : t('MAIN_TITLE')}
      </div>
      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={pathname && [pathname.replace('/', '')]}
        defaultOpenKeys={[getOpenSubItemKey(menuItems, pathname)]}
      >
        {menuItems.map((menuItem) =>
          !menuItem.subItems ? (
            <Menu.Item
              key={menuItem.key}
              onClick={() => history.push(menuItem.path)}
            >
              <>
                {renderIcon(menuItem.icon)}
                <span>{menuItem.title}</span>
              </>
            </Menu.Item>
          ) : (
            <SubMenu
              key={menuItem.key}
              icon={renderIcon(menuItem.icon)}
              title={menuItem.title}
            >
              {menuItem.subItems.map((subItem) => (
                <Menu.Item
                  key={subItem.key}
                  onClick={() => history.push(subItem.path)}
                >
                  <>
                    <span>{subItem.title}</span>
                  </>
                </Menu.Item>
              ))}
            </SubMenu>
          )
        )}
      </Menu>
    </Sider>
  )
}

export default withRouter(SidebarMenu)
