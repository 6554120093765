import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  flexRow: { display: 'flex' },
  rightSpaced: { marginRight: 10, width: '100%' },
  fullWidth: { width: '100%' },
  btnContainer: { display: 'flex', justifyContent: 'flex-end' },
  cancelBtn: { marginRight: 5 },
  switch: { minWidth: 300 },
})

export default useStyles
