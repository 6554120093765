import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  filter: {
    padding: 10,
  },
  filterInput: {
    width: 188,
    marginBottom: 8,
    display: 'block',
  },
})

export default useStyles
