import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash'
import { Button, Input, Radio, Space, Tag, message } from 'antd'
import {
  CustomModal,
  DataTable,
  Error,
  Pagination,
  Spinner,
  TableRowActions,
} from 'components'
import {
  deleteDividend,
  getDividendList as getDividendListApi,
  updateDividend,
} from 'api/dividend'
import { REGION, DIVIDEND_STATUS, RESPONSE_STATUS } from 'constants/common'
import { getTableColumns } from './config'
import useStyles from './styles'
import UpdateDividendForm from '../Form/Update'

const LIMIT = 10
const INITIAL_OFFSET = 0

const DividendsTable = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [fetchData, setFetchData] = useState([])
  const [fetchLoading, setFetchLoading] = useState(false)
  const [fetchError, setFetchError] = useState(null)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [reactivateLoading, setReactivateLoading] = useState(false)
  const [limit] = useState(LIMIT)
  const [offset, setOffset] = useState(INITIAL_OFFSET)
  const [status, setStatus] = useState(undefined)
  const [region, setRegion] = useState(undefined)
  const [isDeleted, setIsDeleted] = useState(undefined)
  const [searchTerm, setSearchTerm] = useState(undefined)
  const [symbol, setSymbol] = useState(undefined)
  const [selectedItem, setSelectedItem] = useState(null)
  const [isEditModalVisible, setIsEditModalVisible] = useState(false)

  const fetchDividends = async () => {
    try {
      setFetchLoading(true)
      const response = await getDividendListApi({
        limit,
        start: offset,
        orderBy: 'id desc',
        status,
        region,
        isDeleted,
        symbol,
      })
      setFetchData(get(response, 'data.result'))
      setFetchLoading(false)
    } catch (err) {
      setFetchError(err)
      setFetchData(false)
    }
  }

  useEffect(() => {
    fetchDividends()
  }, [offset])

  useEffect(() => {
    if (offset !== INITIAL_OFFSET) {
      setOffset(INITIAL_OFFSET)
    } else {
      fetchDividends()
    }
  }, [isDeleted, status, symbol, region])

  const handleDelete = async ({ id }) => {
    try {
      setDeleteLoading(true)
      const response = await deleteDividend({ id })
      setDeleteLoading(false)
      message.success(t('MESSAGES.DELETE_SUCCESS'))
      if (response.data.status === RESPONSE_STATUS.SUCCESS) {
        fetchDividends()
      }
    } catch (error) {
      message.error(error.message)
      setDeleteLoading(false)
    }
  }

  const handleReactivate = async ({ id }) => {
    try {
      setReactivateLoading(true)
      const response = await updateDividend({ id, isDeleted: 0 })
      setReactivateLoading(false)
      message.success(t('MESSAGES.REACTIVATE_SUCCESS'))
      if (response.data.status === RESPONSE_STATUS.SUCCESS) {
        fetchDividends()
      }
    } catch (error) {
      message.error(error.message)
      setReactivateLoading(false)
    }
  }

  const handleEdit = (item) => {
    setSelectedItem(item)
    setIsEditModalVisible(true)
  }

  const renderTableRowActions = (item) => (
    <TableRowActions
      item={item}
      handleDelete={handleDelete}
      handleEdit={handleEdit}
      handleReactivate={handleReactivate}
    />
  )

  const getIsDeletedFilterProps = () => ({
    filterDropdown: () => (
      <div className={classes.filter}>
        <Space direction="vertical">
          <Radio.Group
            onChange={(e) => setIsDeleted(e.target.value)}
            value={isDeleted}
          >
            <Space direction="vertical">
              <Radio value={0}>
                <Tag color="blue">{t('COMMON.ACTIVE')}</Tag>
              </Radio>
              <Radio value={1}>
                <Tag color="red">{t('COMMON.NOT_ACTIVE')}</Tag>
              </Radio>
            </Space>
          </Radio.Group>
          <Button size="small" onClick={() => setIsDeleted(undefined)}>
            {t('COMMON.RESET')}
          </Button>
        </Space>
      </div>
    ),
  })

  const getRegionFilterProps = () => ({
    filterDropdown: () => (
      <div className={classes.filter}>
        <Space direction="vertical">
          <Radio.Group
            onChange={(e) => setRegion(e.target.value)}
            value={region}
          >
            <Space direction="vertical">
              {Object.values(REGION).map(({ code }) => (
                <Radio key={code} value={code}>
                  {code}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
          <Button size="small" onClick={() => setRegion(undefined)}>
            {t('COMMON.RESET')}
          </Button>
        </Space>
      </div>
    ),
  })

  const getSymbolFilterProps = () => ({
    filterDropdown: () => (
      <div className={classes.filter}>
        <Input
          className={classes.filterInput}
          placeholder={t('PAGES.DIVIDENDS.TABLE.FIELDS.SYMBOL')}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onPressEnter={() => setSymbol(searchTerm)}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => setSymbol(searchTerm)}
            size="small"
            style={{ width: 90 }}
          >
            {t('COMMON.SEARCH')}
          </Button>
          <Button
            onClick={() => {
              setSearchTerm(undefined)
              setSymbol(undefined)
            }}
            size="small"
            style={{ width: 90 }}
          >
            {t('COMMON.RESET')}
          </Button>
        </Space>
      </div>
    ),
  })

  const getStatusFilterProps = () => ({
    filterDropdown: () => (
      <div className={classes.filter}>
        <Space direction="vertical">
          <Radio.Group
            onChange={(e) => setStatus(e.target.value)}
            value={status}
          >
            <Space direction="vertical">
              <Radio value={DIVIDEND_STATUS[200].status}>
                <Tag color={DIVIDEND_STATUS[200].color}>
                  {t('COMMON.DIV_STATUS_200')}
                </Tag>
              </Radio>
              <Radio value={DIVIDEND_STATUS[300].status}>
                <Tag color={DIVIDEND_STATUS[300].color}>
                  {t('COMMON.DIV_STATUS_300')}
                </Tag>
              </Radio>
            </Space>
          </Radio.Group>
          <Button size="small" onClick={() => setStatus(undefined)}>
            {t('COMMON.RESET')}
          </Button>
        </Space>
      </div>
    ),
  })

  if (fetchLoading || deleteLoading || reactivateLoading) {
    return <Spinner />
  }
  if (fetchError) {
    return <Error message={fetchError.message} />
  }

  return (
    <>
      <DataTable
        data={fetchData}
        loading={fetchLoading}
        columns={getTableColumns({
          t,
          renderTableRowActions,
          getIsDeletedFilterProps,
          getStatusFilterProps,
          getSymbolFilterProps,
          getRegionFilterProps,
        })}
        pagination={false}
      />
      <Pagination
        onPrevClick={() => setOffset(offset - limit)}
        onNextClick={() => setOffset(offset + limit)}
        prevDisabled={offset - limit < 0}
        nextDisabled={fetchData.length < limit}
      />
      <CustomModal
        title={t('PAGES.DIVIDENDS.UPDATE_DIVIDEND')}
        isVisible={isEditModalVisible}
        width={700}
        onCancel={() => setIsEditModalVisible(false)}
        destroyOnClose
      >
        <UpdateDividendForm
          item={selectedItem}
          onCancel={() => setIsEditModalVisible(false)}
          refetchDividends={fetchDividends}
        />
      </CustomModal>
    </>
  )
}

export default DividendsTable
