import React from 'react'
import moment from 'moment'
import { Tag } from 'antd'

export const getTableColumns = ({
  t,
  renderTableRowActions,
  getRegionFilterProps,
  getIsDeletedFilterProps,
}) => [
  {
    title: t('PAGES.COLLECTIONS.TABLE.FIELDS.TITLE'),
    dataIndex: 'title',
    key: 'title',
    width: 200,
  },
  {
    title: t('PAGES.COLLECTIONS.TABLE.FIELDS.DESCRIPTION'),
    dataIndex: 'description',
    key: 'description',
    width: 200,
    render: (description) => description || '-',
  },
  {
    title: t('PAGES.COLLECTIONS.TABLE.FIELDS.REGION'),
    dataIndex: 'region',
    key: 'region',
    width: 70,
    ...getRegionFilterProps(),
  },
  {
    title: t('COMMON.CREATED_AT'),
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 100,
    render: (createdAt) => moment(createdAt).format('L'),
  },
  {
    title: t('COMMON.STATUS'),
    dataIndex: 'isDeleted',
    key: 'isDeleted',
    width: 70,
    render: (isDeleted) =>
      isDeleted ? (
        <Tag color="red">{t('COMMON.NOT_ACTIVE')}</Tag>
      ) : (
        <Tag color="blue">{t('COMMON.ACTIVE')}</Tag>
      ),
    ...getIsDeletedFilterProps(),
  },
  {
    title: t('COMMON.ACTION'),
    key: 'action',
    align: 'center',
    width: 100,
    render: (item) => renderTableRowActions(item),
  },
]
