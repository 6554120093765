import { createUseStyles } from 'react-jss'
import theme from 'config/theme'

const useStyles = createUseStyles({
  loginContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    width: '100%',
    height: '100%',
    backgroundColor: '#f9f9f9',
  },
  loginForm: {
    width: '100%',
    maxWidth: 350,
    padding: 30,
    borderRadius: 12,
    border: '1px solid #f4f4f4',
    boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
    backgroundColor: '#fff',
  },
  logoWrapper: {
    fontSize: 26,
    textAlign: 'center',
    fontWeight: 'bold',
    margin: 30,
    marginTop: 0,
    borderRadius: '5px 15px',
    color: '#fff',
    backgroundColor: theme.color.primary,
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
    KhtmlUserSelect: 'none',
    userSelect: 'none',
    msUserSelect: 'none',
  },
})

export default useStyles
