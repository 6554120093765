import React from 'react'
import moment from 'moment'
import { Avatar } from 'antd'

export const getTableColumns = ({ t }) => [
  {
    title: t('PAGES.USERS.TABLE.FIELDS.AVATAR'),
    dataIndex: 'photoURL',
    key: 'photoURL',
    width: 55,
    render: (photoURL) => <Avatar size={36} src={photoURL} />,
  },
  {
    title: t('PAGES.USERS.TABLE.FIELDS.NAME'),
    dataIndex: 'name',
    key: 'name',
    width: 200,
  },
  {
    title: t('PAGES.USERS.TABLE.FIELDS.EMAIL'),
    dataIndex: 'email',
    key: 'email',
    width: 200,
  },
  {
    title: t('PAGES.USERS.TABLE.FIELDS.LANG'),
    dataIndex: 'lang',
    key: 'lang',
    width: 70,
  },
  {
    title: t('PAGES.USERS.TABLE.FIELDS.PREMIUM'),
    dataIndex: 'premium',
    key: 'premium',
    width: 100,
  },
  {
    title: t('COMMON.REGISTERED_ON'),
    dataIndex: 'created_at',
    key: 'created_at',
    width: 200,
    render: (createdAt) => moment(createdAt).format('LLL'),
  },
]
