import React, { useState, useEffect } from 'react'
import { get } from 'lodash'
import { useTranslation } from 'react-i18next'
import { Select } from 'antd'
import { getStockList as getStockListApi } from 'api/stock'

const LIMIT = 10
const OFFSET = 0
const { Option } = Select

const StockSelect = ({ ...otherProps }) => {
  const { t } = useTranslation()

  const [fetchData, setFetchData] = useState([])
  const [fetchLoading, setFetchLoading] = useState(false)
  const [fetchError, setFetchError] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [searchingItem, setSearchingItem] = useState('')

  const fetchStocks = async () => {
    try {
      setFetchLoading(true)
      const response = await getStockListApi({
        limit: LIMIT,
        start: OFFSET,
        orderBy: 'symbol asc',
        region: 'TR',
        symbol: searchingItem && searchingItem,
      })
      setFetchData(get(response, 'data.result'))
      setFetchLoading(false)
    } catch (err) {
      setFetchError(err)
      setFetchLoading(false)
    }
  }

  useEffect(() => {
    fetchStocks()
  }, [searchingItem])

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!searchTerm) {
        setSearchingItem('')
        return
      }
      setSearchingItem(searchTerm)
    }, 600)

    return () => clearTimeout(timeout)
  }, [searchTerm])

  if (fetchLoading) return <Select placeholder={t('COMMON.LOADING')} />
  if (fetchError) return <Select placeholder={fetchError.message} />

  return (
    <Select
      showSearch
      placeholder={t('PAGES.DIVIDENDS.SELECT_STOCK')}
      filterOption={(input, option) =>
        option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
        option.symbol.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      onSearch={setSearchTerm}
      loading={fetchLoading}
      {...otherProps}
    >
      {fetchData.map((stock) => (
        <Option
          key={stock.id}
          value={stock.id}
          name={stock.name}
          symbol={stock.symbol}
        >
          {stock.symbol} - {stock.name}
        </Option>
      ))}
    </Select>
  )
}

export default StockSelect
