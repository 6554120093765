import React from 'react'
import moment from 'moment'
import { Tag } from 'antd'

export const getTableColumns = ({
  t,
  renderTableRowActions,
  getRegionFilterProps,
  getIsDeletedFilterProps,
  getSymbolFilterProps,
}) => [
  {
    title: t('PAGES.STOCKS.TABLE.FIELDS.SYMBOL'),
    dataIndex: 'symbol',
    key: 'symbol',
    width: 100,
    ...getSymbolFilterProps(),
  },
  {
    title: t('PAGES.STOCKS.TABLE.FIELDS.REGION'),
    dataIndex: 'region',
    key: 'region',
    width: 70,
    ...getRegionFilterProps(),
  },
  {
    title: t('PAGES.STOCKS.TABLE.FIELDS.NAME'),
    dataIndex: 'name',
    key: 'name',
    width: 250,
  },
  {
    title: t('PAGES.STOCKS.TABLE.FIELDS.EXCHANGE'),
    dataIndex: 'exchangeName',
    key: 'exchangeName',
    width: 150,
  },
  {
    title: t('COMMON.UPDATED_AT'),
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    width: 100,
    render: (updatedAt) => moment(updatedAt).format('L'),
  },
  {
    title: t('COMMON.STATUS'),
    dataIndex: 'isDeleted',
    key: 'isDeleted',
    width: 70,
    render: (isDeleted) =>
      isDeleted ? (
        <Tag color="red">{t('COMMON.NOT_ACTIVE')}</Tag>
      ) : (
        <Tag color="blue">{t('COMMON.ACTIVE')}</Tag>
      ),
    ...getIsDeletedFilterProps(),
  },
  {
    title: t('COMMON.ACTION'),
    key: 'action',
    align: 'center',
    width: 100,
    render: (item) => renderTableRowActions(item),
  },
]
