import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Divider, Form, Input, Button, Space } from 'antd'
import { ContentLayout } from 'layouts'
import { copyToClipboard } from 'utils/common'
import useStyles from './styles'

const CalculateYield = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [yieldRate, setYieldRate] = useState(undefined)

  const handleFormFinish = ({ dividendPerShare, pricePerShare }) => {
    const rawYieldRate =
      ((dividendPerShare.includes(',')
        ? dividendPerShare.replace(',', '.')
        : dividendPerShare) /
        (pricePerShare.includes(',')
          ? pricePerShare.replace(',', '.')
          : pricePerShare)) *
      100
    setYieldRate(rawYieldRate.toFixed(2))
  }

  return (
    <ContentLayout
      pageTitle={t('PAGES.TOOLS.CALCULATE_YIELD.CALCULATE_YIELD_RATE')}
    >
      <div className={classes.root}>
        <Form
          onFinish={handleFormFinish}
          layout="vertical"
          requiredMark={false}
        >
          <Form.Item
            label={t('PAGES.TOOLS.CALCULATE_YIELD.AMOUNT_PER_SHARE')}
            name="dividendPerShare"
            rules={[{ required: true }]}
          >
            <Input
              placeholder={t('PAGES.TOOLS.CALCULATE_YIELD.AMOUNT_PER_SHARE')}
            />
          </Form.Item>
          <Form.Item
            label={t('PAGES.TOOLS.CALCULATE_YIELD.SHARE_PRICE')}
            name="pricePerShare"
            rules={[{ required: true }]}
          >
            <Input placeholder={t('PAGES.TOOLS.CALCULATE_YIELD.SHARE_PRICE')} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {t('COMMON.CALCULATE')}
            </Button>
          </Form.Item>
        </Form>
        <Divider />
        {yieldRate && (
          <Space>
            <div>{t('PAGES.TOOLS.CALCULATE_YIELD.DIVIEND_YIELD')} =</div>
            <Input addonBefore="%" value={yieldRate} />
            <Button
              htmlType="submit"
              onClick={() => copyToClipboard(yieldRate)}
            >
              {t('COMMON.COPY')}
            </Button>
          </Space>
        )}
      </div>
    </ContentLayout>
  )
}

export default CalculateYield
