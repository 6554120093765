import axios from 'utils/axios'

export const getDividendList = async ({
  start,
  limit,
  orderBy,
  isDeleted,
  status,
  region,
  symbol,
}) => {
  return axios.post('/dividend/select', {
    start,
    limit,
    orderBy,
    isDeleted,
    status,
    region,
    symbol,
  })
}

export const deleteDividend = async ({ id }) => {
  return axios.post('/dividend/delete', {
    dividendId: id,
  })
}

export const insertDividend = async ({
  stockId,
  amount,
  exDate,
  paymentDate,
  status,
}) => {
  return axios.post('/dividend/insert', {
    stockId,
    amount,
    exDate,
    paymentDate,
    status,
  })
}

export const updateDividend = async ({
  id,
  amount,
  exDate,
  paymentDate,
  status,
  isDeleted,
}) => {
  return axios.post('/dividend/update', {
    dividendId: id,
    amount,
    exDate,
    paymentDate,
    status,
    isDeleted,
  })
}
