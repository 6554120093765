import React from 'react'
import { Button } from 'antd'
import useStyles from './styles'

const CustomButton = ({ fullWidth, boldText, children, ...otherProps }) => {
  const classes = useStyles({ fullWidth, boldText })
  return (
    <Button className={classes.button} {...otherProps}>
      {children}
    </Button>
  )
}

export default CustomButton
