import React from 'react'
import { MainLayout } from 'layouts'
import { SEO } from 'components'

const Dashboard = () => {
  return (
    <>
      <SEO />
      <MainLayout />
    </>
  )
}

export default Dashboard
