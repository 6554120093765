import React from 'react'
import {
  DashboardOutlined,
  LineChartOutlined,
  PieChartOutlined,
  ScissorOutlined,
  UserOutlined,
  BarsOutlined,
} from '@ant-design/icons'
import { get, filter } from 'lodash'

export const renderIcon = (type) => {
  switch (type) {
    case 'dashboard':
      return <DashboardOutlined />
    case 'stock':
      return <LineChartOutlined />
    case 'dividend':
      return <PieChartOutlined />
    case 'tools':
      return <ScissorOutlined />
    case 'users':
      return <UserOutlined />
    case 'collection':
      return <BarsOutlined />
    default:
      return null
  }
}

export const getOpenSubItemKey = (menuItems, pathname) => {
  return get(
    filter(menuItems, {
      subItems: [{ key: pathname.replace('/', '') }],
    })[0],
    'key'
  )
}
