import React, { useContext, useReducer } from 'react'
import { get } from 'lodash'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ADMIN_EMAILS } from 'constants/common'
import { AuthContext } from 'context/auth'
import { authReducer } from 'reducers/auth'
import { getItem } from 'utils/store'
import {
  CalculateYield,
  Collections,
  Dashboard,
  Login,
  Stocks,
  Dividends,
  Users,
  ParseUrl,
} from 'pages'
import './app.css'

const App = () => {
  const initialAuthState = useContext(AuthContext)
  const [authState, authDispatch] = useReducer(authReducer, initialAuthState)

  const currentUser = getItem('currentUser')
  const isAuthed =
    currentUser && ADMIN_EMAILS.includes(get(currentUser, 'email'))

  const LandingRoute = () => (
    <Route
      render={() =>
        !isAuthed ? (
          <Redirect to={{ pathname: '/login' }} />
        ) : (
          <Redirect to={{ pathname: '/dashboard' }} />
        )
      }
    />
  )

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        !isAuthed ? (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  )

  const LoginRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        !isAuthed ? (
          <Login {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/dashboard', state: { from: props.location } }}
          />
        )
      }
    />
  )

  return (
    <AuthContext.Provider value={{ authState, authDispatch }}>
      <Switch>
        <LandingRoute exact path="/" />
        <Route path="/login" render={(props) => <LoginRoute {...props} />} />
        <PrivateRoute path="/dashboard" component={Dashboard} />
        <PrivateRoute path="/stocks" component={Stocks} />
        <PrivateRoute path="/dividends" component={Dividends} />
        <PrivateRoute path="/users" component={Users} />
        <PrivateRoute path="/collections" component={Collections} />
        <PrivateRoute path="/calculateYield" component={CalculateYield} />
        <PrivateRoute path="/parseUrl" component={ParseUrl} />
        <Redirect to="/" />
      </Switch>
    </AuthContext.Provider>
  )
}

export default App
