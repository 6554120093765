import React from 'react'
import { useTranslation } from 'react-i18next'
import { ContentLayout } from 'layouts'
import { SEO } from 'components'
import UsersTable from './Table'

const Users = () => {
  const { t } = useTranslation()

  return (
    <>
      <SEO title={t('PAGES.USERS.TITLE')} />
      <ContentLayout pageTitle={t('PAGES.USERS.TITLE')}>
        <UsersTable />
      </ContentLayout>
    </>
  )
}

export default Users
