import React from 'react'
import { useTranslation } from 'react-i18next'
import { Select, Tag } from 'antd'
import { DIVIDEND_STATUS } from 'constants/common'

const { Option } = Select

const StatusSelect = ({ firstSelected = false, ...otherProps }) => {
  const { t } = useTranslation()

  return (
    <Select
      showSearch
      placeholder={t('COMMON.DIV_SELECT_STATUS')}
      defaultValue={firstSelected ? Object.keys(DIVIDEND_STATUS)[0] : undefined}
      filterOption={(input, option) =>
        option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      {...otherProps}
    >
      {Object.entries(DIVIDEND_STATUS).map((status) => (
        <Option
          key={status[0]}
          value={status[0]}
          title={t(`COMMON.DIV_STATUS_${status[0]}`)}
        >
          <Tag color={status[1].color}>
            {t(`COMMON.DIV_STATUS_${status[0]}`)}
          </Tag>
        </Option>
      ))}
    </Select>
  )
}

export default StatusSelect
