import React from 'react'
import moment from 'moment'
import { Tag } from 'antd'
import { get } from 'lodash'
import { DIVIDEND_STATUS } from 'constants/common'

export const getTableColumns = ({
  t,
  renderTableRowActions,
  getIsDeletedFilterProps,
  getStatusFilterProps,
  getSymbolFilterProps,
  getRegionFilterProps,
}) => [
  {
    title: t('PAGES.DIVIDENDS.TABLE.FIELDS.SYMBOL'),
    dataIndex: 'symbol',
    key: 'symbol',
    width: 100,
    ...getSymbolFilterProps(),
  },
  {
    title: t('PAGES.DIVIDENDS.TABLE.FIELDS.REGION'),
    dataIndex: 'region',
    key: 'region',
    width: 90,
    ...getRegionFilterProps(),
  },
  {
    title: t('PAGES.DIVIDENDS.TABLE.FIELDS.EX_DATE'),
    dataIndex: 'exDate',
    key: 'exDate',
    render: (exDate) => (exDate ? moment(exDate).format('L') : '-'),
  },
  {
    title: t('PAGES.DIVIDENDS.TABLE.FIELDS.PAYMENT_DATE'),
    dataIndex: 'paymentDate',
    key: 'paymentDate',
    render: (paymentDate) =>
      paymentDate ? moment(paymentDate).format('L') : '-',
  },
  {
    title: t('PAGES.DIVIDENDS.TABLE.FIELDS.AMOUNT'),
    dataIndex: 'amount',
    key: 'amount',
    align: 'right',
  },
  {
    title: t('PAGES.DIVIDENDS.TABLE.FIELDS.CURRENCY'),
    dataIndex: 'currency',
    key: 'currency',
    width: 80,
  },
  {
    title: t('PAGES.DIVIDENDS.TABLE.FIELDS.YIELD'),
    dataIndex: 'yieldRate',
    key: 'yieldRate',
    align: 'right',
    width: 80,
    render: (yieldRate) =>
      yieldRate ? `%${(yieldRate * 100).toFixed(2)}` : '-',
  },
  {
    title: t('PAGES.DIVIDENDS.TABLE.FIELDS.DIV_STATUS'),
    dataIndex: 'status',
    key: 'status',
    width: 140,
    render: (status) => (
      <Tag color={get(DIVIDEND_STATUS[status], 'color')}>
        {t(`COMMON.DIV_STATUS_${status}`)}
      </Tag>
    ),
    ...getStatusFilterProps(),
  },
  {
    title: t('COMMON.UPDATED_AT'),
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    width: 120,
    render: (updatedAt) => moment(updatedAt).format('L'),
  },
  {
    title: t('COMMON.STATUS'),
    dataIndex: 'isDeleted',
    key: 'isDeleted',
    width: 100,
    render: (isDeleted) =>
      isDeleted ? (
        <Tag color="red">{t('COMMON.NOT_ACTIVE')}</Tag>
      ) : (
        <Tag color="blue">{t('COMMON.ACTIVE')}</Tag>
      ),
    ...getIsDeletedFilterProps(),
  },
  {
    title: t('COMMON.ACTION'),
    key: 'action',
    align: 'center',
    width: 100,
    render: (item) => renderTableRowActions(item),
  },
]
