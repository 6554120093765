import { message } from 'antd'

export const copyToClipboard = async (text) => {
  try {
    const el = document.createElement('textarea')
    el.value = text
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    message.success('Success')
  } catch (err) {
    message.error(err.message)
  }
}
