export const TRANSLATIONS_EN = {
  PROJECT_NAME: 'Dividend',
  MAIN_TITLE: 'dividend.',
  MAIN_SHORT_TITLE: 'di.',
  COMMON: {
    ACTIVE: 'Active',
    NOT_ACTIVE: 'Inactive',
    CREATED_AT: 'Created At',
    REGISTERED_ON: 'Registered On',
    UPDATED_AT: 'Updated At',
    BACK: 'Back',
    NEXT: 'Next',
    STATUS: 'Status',
    ACTION: 'Action',
    RESET: 'Reset',
    SEARCH: 'Search',
    EDIT: 'Edit',
    DELETE: 'Delete',
    REACTIVATE: 'Reactivate',
    DIV_STATUS_200: 'Approved',
    DIV_STATUS_300: 'Pending',
    ADD_NEW: '+ Add',
    CANCEL: 'Cancel',
    CREATE: 'Create',
    UPDATE: 'Update',
    LOADING: 'Loading...',
    DIV_SELECT_STATUS: 'Select dividend status',
    CALCULATE: 'Calculate',
    COPY: 'Copy',
    LOGIN: 'Sign In',
    LOGOUT: 'Sign Out',
  },
  MESSAGES: {
    UPDATE_SUCCESS: 'Successfully updated',
    DELETE_SUCCESS: 'Successfully deleted',
    REACTIVATE_SUCCESS: 'Successfully reactivated',
    AUTH_ERROR: 'Unauthorized',
  },
  PAGES: {
    STOCKS: {
      TITLE: 'Stocks',
      UPDATE_STOCK: 'Update Stock',
      SELECT_REGION: 'Select region',
      TABLE: {
        FIELDS: {
          SYMBOL: 'Symbol',
          NAME: 'Name',
          REGION: 'Region',
          EXCHANGE: 'Exchange',
        },
      },
    },
    DIVIDENDS: {
      TITLE: 'Dividends',
      ADD_DIVIDEND: 'Add Dividend',
      UPDATE_DIVIDEND: 'Update Dividend',
      SELECT_STOCK: 'Select stock',
      TABLE: {
        FIELDS: {
          SYMBOL: 'Symbol',
          STOCK: 'Stock',
          REGION: 'Region',
          EX_DATE: 'Ex. Date',
          PAYMENT_DATE: 'Payment Date',
          GROSS: 'Gross',
          GROSS_AMOUNT: 'Gross Amount',
          NET: 'Net',
          AMOUNT: 'Net Amount',
          CURRENCY: 'Currency',
          YIELD: 'Yield Rate',
          DIV_STATUS: 'Div. Status',
        },
      },
    },
    USERS: {
      TITLE: 'Users',
      TABLE: {
        FIELDS: {
          AVATAR: 'Avatar',
          NAME: 'Name',
          EMAIL: 'Email',
          PREMIUM: 'Premium',
          LANG: 'Language',
        },
      },
    },
    COLLECTIONS: {
      TITLE: 'Collections',
      ADD_COLLECTION: 'Add Collection',
      UPDATE_COLLECTION: 'Update Collection',
      SELECT_REGION: 'Select region',
      SELECT_COMPONENT_TYPE: 'Select component type',
      TABLE: {
        FIELDS: {
          TITLE: 'Title',
          REGION: 'Region',
          DESCRIPTION: 'Description',
          ICON: 'Icon',
          QUERY: 'SQL Query',
          COMPONENT_TYPE: 'Component Type',
        },
      },
    },
    TOOLS: {
      CALCULATE_YIELD: {
        CALCULATE_YIELD_RATE: 'Calculate Yield',
        AMOUNT_PER_SHARE: 'Dividend amount',
        SHARE_PRICE: 'Share price',
        DIVIEND_YIELD: 'Dividend Yield',
      },
      PARSE_URL: {
        CREATE_POST: 'Create Post',
        POST_TYPE: 'Post Type',
        NOTIFICATION: 'Notification',
        NOTIFICATION_TYPE: 'Notification type',
        STOCK_SYMBOL: 'Stock symbol',
        STOCK_NAME: 'Stock name',
        LATEST_PRICE: 'Latest price',
        AMOUNT_GROSS: 'Amount (gross)',
        AMOUNT_NET: 'Amount (net)',
        YIELD_RATE: 'Yield rate',
        DATE: 'Date',
        PAYMENT_DATE: 'Payment date',
        STATUS: 'Status',
        SOURCE: 'Source',
        TOTAL_AMOUNT_NET: 'Total amount (net)',
        TOTAL_YIELD_RATE: 'Total yield rate',
      },
    },
  },
}
