import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'antd'
import { ContentLayout } from 'layouts'
import { CustomModal, SEO } from 'components'
import CollectionsTable from './Table'
import AddCollectionForm from './Form/Add'

const Collections = () => {
  const { t } = useTranslation()

  const [isAddModalVisible, setIsAddModalVisible] = useState(false)

  const handleModalCancel = () => {
    setIsAddModalVisible(false)
  }
  const handleAddNewClick = () => setIsAddModalVisible(true)

  const renderTopRightHeader = () => (
    <>
      <Button onClick={handleAddNewClick}>{t('COMMON.ADD_NEW')}</Button>
      <CustomModal
        title={t('PAGES.COLLECTIONS.ADD_COLLECTION')}
        isVisible={isAddModalVisible}
        width={700}
        onCancel={handleModalCancel}
        destroyOnClose
      >
        <AddCollectionForm onCancel={handleModalCancel} />
      </CustomModal>
    </>
  )

  return (
    <>
      <SEO title={t('PAGES.COLLECTIONS.TITLE')} />
      <ContentLayout
        pageTitle={t('PAGES.COLLECTIONS.TITLE')}
        renderTopRightHeader={renderTopRightHeader}
      >
        <CollectionsTable />
      </ContentLayout>
    </>
  )
}

export default Collections
