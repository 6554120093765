import React from 'react'
import { Table } from 'antd'
import { uniqueId } from 'lodash'

const DataTable = ({ data, columns, ...otherProps }) => (
  <Table
    size="small"
    dataSource={data}
    pagination={{ simple: true, pageSize: 25 }}
    columns={columns}
    rowKey={(item) => item.id || uniqueId()}
    scroll={{ x: 1000, y: 500 }}
    {...otherProps}
  />
)

export default DataTable
