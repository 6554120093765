import React from 'react'
import Helmet from 'react-helmet'
import { useTranslation } from 'react-i18next'

const SEO = ({ title }) => {
  const { t } = useTranslation()

  const titleToShow = title
    ? `${title} - ${t('PROJECT_NAME')} Panel`
    : `${t('PROJECT_NAME')} Panel`

  return (
    <Helmet>
      <title>{titleToShow}</title>
    </Helmet>
  )
}

export default SEO
