import { createUseStyles } from 'react-jss'
import theme from 'config/theme'

const useStyles = createUseStyles({
  root: {
    padding: 20,
    width: '100%',
    display: 'flex',
    [`@media screen and (max-width: ${theme.screen.md})`]: {
      flexDirection: 'column',
    },
  },
  form: {
    marginBottom: 20,
    maxWidth: 500,
    width: '100%',
    marginRight: 50,
  },
  content: {
    width: '100%',
  },
  post: {
    marginTop: 30,
  },
})

export default useStyles
