import React from 'react'
import { get } from 'lodash'
import { useTranslation } from 'react-i18next'
import { Dropdown, Menu } from 'antd'
import { DownOutlined } from '@ant-design/icons'

const TableRowActions = ({
  item,
  handleDelete,
  handleEdit,
  handleReactivate,
}) => {
  const { t } = useTranslation()

  const getDeleteMenuItem = () => {
    if (get(item, 'isDeleted') === 0) {
      return (
        <Menu.Item key="DELETE" onClick={() => handleDelete(item)}>
          {t('COMMON.DELETE')}
        </Menu.Item>
      )
    }
    if (get(item, 'isDeleted') === 1) {
      return (
        <Menu.Item key="REACTIVATE" onClick={() => handleReactivate(item)}>
          {t('COMMON.REACTIVATE')}
        </Menu.Item>
      )
    }
    return null
  }

  const actionMenu = (
    <Menu>
      <Menu.Item key="EDIT" onClick={() => handleEdit(item)}>
        {t('COMMON.EDIT')}
      </Menu.Item>
      {getDeleteMenuItem()}
    </Menu>
  )

  return (
    <Dropdown overlay={actionMenu} placement="bottomCenter" trigger={['click']}>
      <DownOutlined />
    </Dropdown>
  )
}

export default TableRowActions
