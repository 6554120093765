import React, { useContext } from 'react'
import { get } from 'lodash'
import { useGoogleLogin } from 'react-google-login'
import { useTranslation } from 'react-i18next'
import { message } from 'antd'
import { GoogleOutlined } from '@ant-design/icons'
import { CustomButton } from 'components'
import { AuthContext } from 'context/auth'
import { ADMIN_EMAILS } from 'constants/common'
import { storeItem } from 'utils/store'
import { refreshTokenSetup } from 'utils/refreshToken'
import useStyles from './styles'

const Login = () => {
  const classes = useStyles()
  const { authDispatch } = useContext(AuthContext)
  const { t } = useTranslation()

  const onSuccess = (res) => {
    const email = get(res.profileObj, 'email')

    if (!ADMIN_EMAILS.includes(email)) {
      message.error(t('MESSAGES.AUTH_ERROR'))
      return
    }
    authDispatch({
      type: 'LOGIN_USER',
      payload: res.profileObj,
    })
    storeItem('currentUser', res.profileObj)
    refreshTokenSetup(res)
    window.location = '/'
  }

  const onFailure = ({ error }) => {
    message.error(error)
  }

  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    accessType: 'offline',
  })

  return (
    <div className={classes.loginContainer}>
      <div className={classes.loginForm}>
        <div className={classes.logoWrapper}>{t('MAIN_TITLE')}</div>
        <CustomButton
          icon={<GoogleOutlined />}
          type="primary"
          onClick={signIn}
          size="large"
          fullWidth
          boldText
          loading={false}
        >
          {t('COMMON.LOGIN')}
        </CustomButton>
      </div>
    </div>
  )
}

export default Login
