import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { TRANSLATIONS_EN } from './en'
import { TRANSLATIONS_TR } from './tr'

i18n.use(initReactI18next).init({
  resources: {
    EN: {
      translation: TRANSLATIONS_EN,
    },
    TR: {
      translation: TRANSLATIONS_TR,
    },
  },
})

export default i18n
