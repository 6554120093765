export const TRANSLATIONS_TR = {
  PROJECT_NAME: 'Temettü',
  MAIN_TITLE: 'temettü.',
  MAIN_SHORT_TITLE: 'te.',
  COMMON: {
    ACTIVE: 'Aktif',
    NOT_ACTIVE: 'İnaktif',
    CREATED_AT: 'Oluşturuldu',
    REGISTERED_ON: 'Kayıt Tarihi',
    UPDATED_AT: 'Son Güncelleme',
    BACK: 'Geri',
    NEXT: 'İleri',
    STATUS: 'Durum',
    ACTION: 'Aksiyon',
    RESET: 'Sıfırla',
    SEARCH: 'Ara',
    EDIT: 'Düzenle',
    DELETE: 'Sil',
    REACTIVATE: 'Yeniden Aktifleştir',
    DIV_STATUS_200: 'Karar Verildi',
    DIV_STATUS_300: 'Karar Aşamasında',
    ADD_NEW: '+ Ekle',
    CANCEL: 'İptal',
    CREATE: 'Oluştur',
    UPDATE: 'Güncelle',
    LOADING: 'Yükleniyor...',
    DIV_SELECT_STATUS: 'Temettü durumunu seçiniz',
    CALCULATE: 'Hesapla',
    COPY: 'Kopyala',
    LOGIN: 'Giriş Yap',
    LOGOUT: 'Çıkış Yap',
  },
  MESSAGES: {
    UPDATE_SUCCESS: 'Başarıyla güncellendi',
    DELETE_SUCCESS: 'Başarıyla silindi',
    REACTIVATE_SUCCESS: 'Başarıyla yeniden aktifleştirildi',
    AUTH_ERROR: 'Yetkiniz bulunmuyor',
  },
  PAGES: {
    STOCKS: {
      TITLE: 'Hisseler',
      UPDATE_STOCK: 'Hisseyi Güncelle',
      SELECT_REGION: 'Bölge seçiniz',
      TABLE: {
        FIELDS: {
          SYMBOL: 'Sembol',
          NAME: 'İsim',
          REGION: 'Bölge',
          EXCHANGE: 'Borsa',
        },
      },
    },
    DIVIDENDS: {
      TITLE: 'Temettüler',
      ADD_DIVIDEND: 'Temettü Ekle',
      UPDATE_DIVIDEND: 'Temettüyü Güncelle',
      SELECT_STOCK: 'Hisse seçiniz',
      TABLE: {
        FIELDS: {
          SYMBOL: 'Sembol',
          STOCK: 'Hisse',
          REGION: 'Bölge',
          EX_DATE: 'Tarih',
          PAYMENT_DATE: 'Ödeme Tarihi',
          GROSS: 'Brüt',
          GROSS_AMOUNT: 'Brüt Miktar',
          NET: 'Net',
          AMOUNT: 'Net Miktar',
          CURRENCY: 'Birim',
          YIELD: 'Verim',
          DIV_STATUS: 'Tmt. Durum',
        },
      },
    },
    USERS: {
      TITLE: 'Kullanıcılar',
      TABLE: {
        FIELDS: {
          AVATAR: 'Avatar',
          NAME: 'İsim',
          EMAIL: 'Email',
          PREMIUM: 'Premium',
          LANG: 'Dil',
        },
      },
    },
    COLLECTIONS: {
      TITLE: 'Koleksiyonlar',
      ADD_COLLECTION: 'Koleksiyon Ekle',
      UPDATE_COLLECTION: 'Koleksiyonu Güncelle',
      SELECT_REGION: 'Bölge seçiniz',
      SELECT_COMPONENT_TYPE: 'Component tipi seçiniz',
      TABLE: {
        FIELDS: {
          TITLE: 'Başlık',
          REGION: 'Bölge ',
          DESCRIPTION: 'Açıklama',
          ICON: 'İkon',
          QUERY: 'SQL Sorgu',
          COMPONENT_TYPE: 'Component Tipi',
        },
      },
    },
    TOOLS: {
      CALCULATE_YIELD: {
        CALCULATE_YIELD_RATE: 'Verim Hesapla',
        AMOUNT_PER_SHARE: 'Temettü miktarı',
        SHARE_PRICE: 'Birim hisse fiyatı',
        DIVIEND_YIELD: 'Temettü Verimi',
      },
      PARSE_URL: {
        CREATE_POST: 'Gönderi Oluştur',
        POST_TYPE: 'Gönderi Tipi',
        NOTIFICATION: 'Bildiri',
        NOTIFICATION_TYPE: 'Bildiri tipi',
        STOCK_SYMBOL: 'Hisse sembolü',
        STOCK_NAME: 'Hisse ismi',
        LATEST_PRICE: 'Son fiyat',
        AMOUNT_GROSS: 'Pay başı (brüt)',
        AMOUNT_NET: 'Pay başı (net)',
        YIELD_RATE: 'Verim',
        DATE: 'Tarih',
        PAYMENT_DATE: 'Ödeme tarihi',
        STATUS: 'Durum',
        SOURCE: 'Kaynak',
        TOTAL_AMOUNT_NET: 'Toplam pay başı (net)',
        TOTAL_YIELD_RATE: 'Toplam verim',
      },
    },
  },
}
